/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 */
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.


/***************************************************************************************************
 * APPLICATION IMPORTS
 */

// Overriding alerts and confirm boxes via sweet alert 2 -> https://sweetalert2.github.io/#download
import Swal, { SweetAlertResult, SweetAlertOptions, SweetAlertPosition } from 'sweetalert2';
import { Logger, Toast, ICONS } from './constants';
import { environment } from './environments/environment';
// importing adapter.js shim to make all browsers compatible to the apis we are using
import adapter from 'webrtc-adapter';
window.console.log('Browser Details:', adapter.browserDetails);

// global object is used in node, but is not available in browser
(window as any).global = window;



// defining logger as global for ts, and as window for application
declare global {
	var logger: Logger;
	var toast: Toast;
	var confirmBox: (msg: { title?: string, text: string, icon?: ICONS, confirmButtonText?: string, cancelButtonText?: string, confirmButtonColor?: string, cancelButtonColor?: string, showCloseButton?: boolean, reverseButtons?: boolean, containerClass?: string }) => Promise<SweetAlertResult>;
	var infoBox: (msg: string | { title?: string, text?: string }, btnText?: string) => Promise<SweetAlertResult>;
	var dismissToast: () => void;
	var stickyPopup: (text: string) => void;
	var dismissStickyPopup: () => void;
	var isMobile: boolean;
	var isIOS: boolean;
	var isAndroid: boolean;
	var ICON: typeof ICONS;
	var logFile: Array<any>;
	var fetchResource: (string, boolean?) => string;
	var iconURLS: any;
	var disableToasts: boolean;
	var isChrome: boolean;
	var isFirefox: boolean;
	var InstallTrigger: any;
	// page visibility api usage
	var isPageVisible: () => boolean;
	var setVisibilityListener: (func: () => void) => void;
	var removeVisibilityListener: (func: () => void) => void;
	var setFavicoIcon: () => void;

	// to check whether encryption methods used are suppported by the browser or not
	var isEncryptionSupported: boolean;

	interface Window {
		logger: Logger;
		toast: Toast;
		confirmBox: (msg: { title: string, text: string, icon?: ICONS, confirmButtonText?: string, cancelButtonText?: string, confirmButtonColor?: string, cancelButtonColor?: string, showCloseButton?: boolean, reverseButtons?: boolean, containerClass?: string }) => Promise<SweetAlertResult>;
		infoBox: (msg: string | { title?: string, text?: string }, btnText?: string) => Promise<SweetAlertResult>;
		dismissToast: () => void;
		stickyPopup: (text: string) => void;
		dismissStickyPopup: () => void;
		isMobile: boolean;
		isIOS: boolean;
		ICON: typeof ICONS;
		logFile: Array<any>;
		fetchResource: (string, boolean?) => string;
		iconURLS: any;
		disableToasts: boolean;
		webkitAudioContext: {
			prototype: AudioContext;
			new(contextOptions?: AudioContextOptions): AudioContext;
		};
		chrome: any;
		isChrome: boolean;
		isFirefox: boolean;

		// page visibility api usage
		isPageVisible: () => boolean;
		setVisibilityListener: (func: () => void) => void;
		removeVisibilityListener: (func: () => void) => void;
		setFavicoIcon: () => void;

		// to check whether encryption methods used are suppported by the browser or not
		isEncryptionSupported: boolean;
	}

	interface String {
		capitalizeFirstLetter: () => string;
		getBuffer: () => Uint8Array;
	}

	interface MediaTrackConstraints {
		cursor?: string;
		displaySurface?: string;
	}

	interface MediaDevices {
		getDisplayMedia: (MediaStreamConstraints) => Promise<MediaStream>;
	}

	interface HTMLCanvasElement {
		captureStream: (frameRate?: number) => MediaStream;
	}

	interface HTMLVideoElement {
		captureStream: (frameRate?: number) => MediaStream;
	}

	interface MediaStreamTrack {
		requestFrame: () => void;
	}

	interface MouseEvent {
		path: HTMLElement[];
	}

	interface Document {
		msHidden: any;
		webkitHidden: any;
	}

	interface ArrayBuffer {
		getString: () => string;
	}

	interface Uint8Array {
		getString: () => string;
	}
}


// set this to true if developing for mobile on desktop, this is set in dashboard component
window.isMobile = false;
window.isIOS = false;
window.isAndroid = false;

// capture log in this file
window.logFile = [];

// overriding console to create our seperate logger and supress openvidu's
const supressLogLevels = [
	'info',
	'warn',
	'debug',
	'log',
	'dir'
];

const logLevels = [
	'info',
	'warn',
	'debug',
	'log',
	'dir',
	'error'
];

// use orignal console
window.logger = (() => {
	const obj: Logger = {};
	const dummy = { ...window.console };
	logLevels.forEach(level => {
		const original = window.console[level];
		// writing logs to a file
		window.console[level] = function(...msg) {
			const stack = new Error().stack.split('\n')[2];
			const arr = [];
			msg.forEach(m => {
				try {
					// to check if object is serializable
					JSON.stringify(m);
					arr.push(m);
				} catch (e) {
					if (typeof m.toString == 'function') {
						arr.push(m.toString());
					}
				}
			});
			logFile.push([...arr, stack]);
			original.call(this, ...msg, stack);
		};

		// using bind to print the callee line number on dev tools instead of this file's line number
		obj[level] = window.console[level].bind(window.console, '%c--Application Log--', 'color: blue;');
	});
	supressLogLevels.forEach(level => {
		dummy[level] = (...msg) => { };
	});

	// if (environment.production)
	// @ts-ignore
	// window.console = dummy;
	return obj;
})();


// string formatter, makes first letter capital rest lowercase
String.prototype.capitalizeFirstLetter = function() {
	const source: string = this.replace('_', ' ').toLowerCase();
	const arr = source.split(' ');
	const result = [];
	arr.forEach(str => {
		result.push(str.charAt(0).toUpperCase() + str.slice(1));
	});
	const target = result.join(' ');
	return target;
};


/****** Alerts, Toasts and confirm popups using sweet alert *****/

// disable toast forever
window.disableToasts = false;

// path or base64 data uri of all icons
window.iconURLS = {};

window.ICON = ICONS;

// fetch icon path
const getIconPath = (icon: ICONS) => 'assets/alerts/' + icon + '.svg';

// overriding alert globally
window.alert = (data: any): void => {
	const isObject = typeof data === 'object';
	const { title = '', text, icon = ICON.ERROR } = isObject ? data : { text: data };
	const imageUrl = fetchResource(getIconPath(icon), false);

	Swal.fire({
		title,
		text,
		imageUrl,
		allowOutsideClick: false,
		allowEscapeKey: false,
		allowEnterKey: false,
		heightAuto: false,
		imageWidth: 25,
		imageHeight: 25,
		customClass: icon
	});
};

// toast common config
const toast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timerProgressBar: true,
	allowEscapeKey: false,
	imageHeight: 30,
	customClass: {
		container: 'toast'
	}
});

// show toast notifications
window.toast = (data, timer = 1500, position: SweetAlertPosition = 'top-end') => {
	if (window.disableToasts) {
		return;
	}
	const { text, icon = ICON.INFO } = typeof data === 'object' ? data : { text: data };
	const imageUrl = fetchResource(getIconPath(icon), false);

	const content: SweetAlertOptions = {
		imageUrl,
		title: text,
		timer
	};

	if (isMobile) {
		position = 'top-start';
	}

	content.position = position;

	toast.fire(content);
};

// dismiss it permanently
window.dismissToast = () => {
	toast.close();
	window.disableToasts = true;
};

// confirm dialog custom
window.confirmBox = (data: { title?: string, text: string, icon?: ICONS, confirmButtonText?: string, cancelButtonText?: string, confirmButtonColor?: string, cancelButtonColor?: string, showCloseButton?: boolean, reverseButtons?: boolean, containerClass?: string }) => {
	const iconPath = data.icon || ICON.INFO;
	const imageUrl = fetchResource(getIconPath(iconPath), false);

	return Swal.fire({
		title: data.title || '',
		html: data.text,
		imageUrl,
		showCancelButton: true,
		confirmButtonColor: data.confirmButtonColor || '#3C92F7',
		cancelButtonColor: data.cancelButtonColor || '#FF9735',
		confirmButtonText: data.confirmButtonText || 'Yes',
		cancelButtonText: data.cancelButtonText || 'No',
		heightAuto: false,
		imageWidth: 25,
		imageHeight: 25,
		reverseButtons: (data.reverseButtons != undefined) ? data.reverseButtons : true,
		customClass: { icon: iconPath.toString(), container: data.containerClass || '' },
		showCloseButton: (data.showCloseButton  != undefined) ? data.showCloseButton : false
	});
};

// to display some info, use instead of alert if want non blocking alert
window.infoBox = (msg: string | { title?: string, text?: string }, btnText = 'OK') => {
	const { title = null, text } = typeof msg === 'object' ? msg : { text: msg };
	return Swal.fire({
		title,
		text,
		imageUrl: fetchResource(getIconPath(ICON.INFO), false),
		allowOutsideClick: false,
		allowEscapeKey: true,
		allowEnterKey: true,
		heightAuto: false,
		imageWidth: 25,
		imageHeight: 25,
		confirmButtonText: btnText
		// customClass: icon
	});
};

// toast common config
const stickyPopup = Swal.mixin({
	position: 'center',
	showConfirmButton: false,
	allowEnterKey: false,
	allowOutsideClick: false,
	timerProgressBar: false,
	allowEscapeKey: false,
	heightAuto: false,
	customClass: {
		container: 'stickyPopup'
	}
});

// Non dismissable popup, showing a loader
window.stickyPopup = (text: string) => {
	const content: SweetAlertOptions = {
		title: text,
		imageUrl: fetchResource('assets/loader.gif', false)
	};
	stickyPopup.fire(content);
};

window.dismissStickyPopup = () => {
	stickyPopup.close();
};

/****** End *****/

// Browser detection
window.isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
window.isFirefox = typeof InstallTrigger !== 'undefined';


/** Page Visibility API to check if current tab is visible or not */
// Set the name of the hidden property and the change event for visibility
let hidden, visibilityChange;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
	hidden = 'hidden';
	visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
	hidden = 'msHidden';
	visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
	hidden = 'webkitHidden';
	visibilityChange = 'webkitvisibilitychange';
}

window.isPageVisible = () => hidden !== undefined && !document[hidden];

window.setVisibilityListener = (func: () => void) => {
	if (typeof document.addEventListener !== 'undefined' && visibilityChange !== undefined) {
		document.addEventListener(visibilityChange, func, false);
	}
};

window.removeVisibilityListener = (func: () => void) => {
	if (typeof document.removeEventListener !== 'undefined' && visibilityChange !== undefined) {
		document.removeEventListener(visibilityChange, func);
	}
};

const notifyIconPath = './favicon-notify.ico';
const normalIconPath = './favicon.ico';

window.setFavicoIcon = () => {
	// remove any previous listeners
	removeVisibilityListener(setFavicoIcon);
	const element = document.getElementById('appFavIcon');
	if (element && typeof element.getAttribute == 'function' && typeof element.setAttribute == 'function') {
		const currentIconName = element.getAttribute('href');
		if (currentIconName && currentIconName.trim() != '') {
			// if notify icon showing
			if (currentIconName == notifyIconPath) {
				// page visible -> change icon to normal,remove all listeners
				if (isPageVisible()) {
					element.setAttribute('href', normalIconPath);
					removeVisibilityListener(setFavicoIcon);
				}
				// page not visible -> wait for it to be visible
				else {
					setVisibilityListener(setFavicoIcon);
				}
			} else {
				// page not visible -> show notify icon -> wait for it to be visible
				if (!isPageVisible()) {
					element.setAttribute('href', notifyIconPath);
					setVisibilityListener(setFavicoIcon);
				}
				else {
					// correct icon showing, remove listeners
					removeVisibilityListener(setFavicoIcon);
				}
			}
		}
	}
};

const bufToStr = (buf: Uint8Array): string => {
	try {
		if (typeof window?.btoa == 'function' && typeof String?.fromCharCode == 'function' && typeof Uint8Array !== 'undefined') {
			/* Convert  an ArrayBuffer into a string
				from https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
			*/
			const binaryString = String.fromCharCode.apply(null, buf);
			// binaryString to base64
			return window.btoa(binaryString);
		}
	} catch (e) {
		logger.error('Error in buffer to string conversion', e);
	}
	return null;
};

// Extending array buffer to convert to base64 string
ArrayBuffer.prototype.getString = function() { return bufToStr(new Uint8Array(this)); };
// Extending Uint8Array to convert to base64 string
Uint8Array.prototype.getString = function() { return bufToStr(this); };

// Extending string to convert base64encoded string to array buffer
// https://github.com/mdn/dom-examples/blob/master/web-crypto/import-key/spki.js
String.prototype.getBuffer = function(): Uint8Array {
	try {
		if (typeof window?.atob == 'function' && typeof Uint8Array != 'undefined' && typeof String?.prototype?.charCodeAt == 'function') {

			// base64 decode the string to get the binary data
			const binaryString = window.atob(this);

			// convert from a binary string to an ArrayBuffer
			// from https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
			const buf = new Uint8Array(binaryString.length);
			for (let i = 0; i < binaryString.length; i++) {
				buf[i] = binaryString.charCodeAt(i);
			}
			return buf;
		}
	} catch (e) {
		logger.error('Error in string to buffer conversion', e);
	}
	return null;
};
